import React from 'react'
import T from 'prop-types'
import upperCase from 'lodash/upperCase'

const ResponsiveTitle = ({ className = '', title, as = 'h1', isUpperCase = true }) =>
  React.createElement(
    as,
    {
      className: `is-size-3-mobile is-size-2-tablet is-size-1-widescreen has-text-centered ${className}`,
    },
    isUpperCase ? upperCase(title) : title
  )

ResponsiveTitle.propTypes = {
  title: T.string.isRequired,
  className: T.string,
  as: T.string,
}

export default ResponsiveTitle
