import React from 'react'
import { navigate } from 'gatsby-link'
import styled from '@emotion/styled'
import { useIntl } from 'gatsby-plugin-intl'

import Layout from '../../components/Layout'
import ResponsiveTitle from '../../components/ResponsiveTitle'
import BaseButton from '../../components/BaseButton'
import { TABLET_MIN_BREAKPOINT, WIDESCREEN_MIN_BREAKPOINT } from '../../constants/breakpoints'

const ContactFormWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ContactForm = styled.form`
  width: 100%;
  padding: 2.5rem 1.5rem;

  @media screen and (${TABLET_MIN_BREAKPOINT}) {
    width: 80%;
  }
  @media screen and (${WIDESCREEN_MIN_BREAKPOINT}) {
    width: 60%;
  }
`

const SubmitButton = styled(BaseButton)`
  padding-left: 2rem;
  padding-right: 2rem;
`

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

class ContactPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error))
  }

  render() {
    const { formatMessage } = this.props.intl
    return (
      <Layout>
        <ContactFormWrapper>
          <ResponsiveTitle title={formatMessage({ id: 'contact.form.title' })} />
          <ContactForm
            name="contact"
            method="post"
            action="/contact/thanks/"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={this.handleSubmit}
          >
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type="hidden" name="form-name" value="contact" />
            <div hidden>
              <label>
                Don’t fill this out: <input name="bot-field" onChange={this.handleChange} />
              </label>
            </div>
            <div className="field">
              <label className="label" htmlFor={'name'}>
                {formatMessage({ id: 'contact.form.name' })}
              </label>
              <div className="control">
                <input
                  className="input"
                  type={'text'}
                  name={'name'}
                  onChange={this.handleChange}
                  id={'name'}
                  required={true}
                />
              </div>
            </div>
            <div className="field">
              <label className="label" htmlFor={'email'}>
                {formatMessage({ id: 'contact.form.email' })}
              </label>
              <div className="control">
                <input
                  className="input"
                  type={'email'}
                  name={'email'}
                  onChange={this.handleChange}
                  id={'email'}
                  required={true}
                />
              </div>
            </div>
            <div className="field">
              <label className="label" htmlFor={'message'}>
                {formatMessage({ id: 'contact.form.message' })}
              </label>
              <div className="control">
                <textarea
                  className="textarea"
                  name={'message'}
                  onChange={this.handleChange}
                  id={'message'}
                  required={true}
                />
              </div>
            </div>
            <div className="field">
              <SubmitButton className="button" type="submit">
                {formatMessage({ id: 'contact.form.send' })}
              </SubmitButton>
            </div>
          </ContactForm>
        </ContactFormWrapper>
      </Layout>
    )
  }
}

const ContactPageIntlWrapper = props => {
  const intl = useIntl()
  return <ContactPage {...props} intl={intl} />
}

export default ContactPageIntlWrapper
