import styled from '@emotion/styled'
import colors from '../constants/colors'

const BaseButton = styled.button`
  border-color: ${colors.champagnePink};
  border-radius: 2rem;
  background-color: transparent;
  transition: background-color 0.5s ease;

  &:hover {
    background-color: ${colors.champagnePink};
    border-color: transparent;
  }
`

export default BaseButton
